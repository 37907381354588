<template>
    <div class="tabs-position">
        <div class="tabs-container">
            <div class="tabs">
                <span v-for="(tabDatum, index) of tabs" :key="tabDatum.title" class="tab" :class="tabClass(index , tabDatum.tabNum)">
                    <ion-icon class="tab__icon" :src="tabDatum.icon || ''" alt=""/>
                    <span class="tab__text">{{ tabDatum.title }}&nbsp;</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {defineComponent} from "@vue/runtime-core";
import {IonIcon} from "@ionic/vue";
import {JourneyTab} from "@/models/JourneyTabModel";
import {useStore} from "vuex";
import {getImage} from "@/services/ImagesService";

export default defineComponent({
    name: 'VisualTabs',
    components: {
      IonIcon  
    },
    data() {
        return {
            store: useStore()
        }
    },
    props: {
        currentTab: { type: Number, default: -1 },
        tabs: { type: Array as () => JourneyTab[], default: () => [] as JourneyTab[]},
        activeTabIndexes: { type: Array, default: () => [] as number[]},
        tabType: { type: String, default: 'lesson' }
    },
    methods: {
        tabClass(index: number , tabNum: number) {
            
            /* if (this.$props.currentTab == -1 ) {
                return 'inactive';
            }*/
            if( this.$props.tabType === 'lesson'){
                if (!this.$props.activeTabIndexes.includes(tabNum - 1)) return 'inactive';
            }
            console.log(`index: ${index}`);
            console.log(`currentTab: ${this.$props.currentTab}`);
            console.log(`tabNum: ${this.$props.tabs[index].tabNum}`);
            if (this.$props.currentTab + 1 === this.$props.tabs[index].tabNum) {
                return 'active';
            }
            

        },
        async loadIcons () {

            if (this.$props.tabs[0]) {
                const {tabIcons} = await this.store.getters['app/language'];
                //const { tabIcons }: { tabIcons: string[] } = { tabIcons: [] };
                const tabIconsViewed: string[] = [];
                const tabs = this.$props.tabs as JourneyTab[];
                for (let i=0; i < tabs.length; i++) {
                    if (tabs[i].tabIconId === '' || tabs[i].tabIconId ==='00000000-0000-0000-0000-000000000000' || tabs[i].tabIconId === undefined) {

                        tabIconsViewed.push('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=');

                    }
                    else {
                        const iconImage = await getImage(tabs[i].tabIconId);
                        tabIconsViewed.push(iconImage.data);
                    }
                } 

                tabs.forEach((tab: JourneyTab, index: number) => tab.icon = tabIconsViewed[index]);
            }
        }
    },
    async ionViewWillEnter() {
        await this.loadIcons();
    },
    async beforeUpdate() {
        await this.loadIcons();
    },
    async mounted() {
        await this.loadIcons();
    }
});
</script>

<style scoped>
.tab__icon {
    font-size: 20px;
    margin: 0 auto;
}

.tabs-position {
    position: sticky;
    margin-bottom: 10px;
}

.tabs{
    width: 100%;
    display: flex;
    overflow-y: hidden;
}

.tabs-container {
    height: 70px;
    display: flex;
    justify-content: center;
    background: var(--ion-color-primary);
    margin-top: 20px;
}

.tabs-container:last-of-type{
    margin-top: 30px;
}

.tab {
    box-sizing: border-box;
    background: var(--ion-color-primary);
    color: var(--color-step-1000);
    display: flex;
    white-space: nowrap;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
    gap: 5px;
}

.tab .tab__text {
    font-size: 13px;
    font-weight: bold;
}

.ltr .tab {
    padding: 13px 15px 12px 30px;
}
.ltr .tab:not(:last-child) {
    border-right: 5px solid var(--color-step-1000);
}
.rtl .tab {
    padding: 13px 30px 12px 15px;
}
.rtl .tab:not(:last-child) {
    border-left: 5px solid var(--color-step-1000);
}

.ltr .tab:first-child {
    padding-left: 15px;
}
.rtl .tab:first-child {
    padding-right: 15px;
}

.tab.inactive {
    background-color: var(--color-step-800);
}
.tab.active {
    color: var(--ion-color-primary);
    background: var(--color-step-1000);
    z-index: 1;
}
.ltr .tab.active:not(:last-child) {
    border-right: 5px solid var(--ion-color-primary);
}
.rtl .tab.active:not(:last-child) {
    border-left: 5px solid var(--ion-color-primary);
}

.ltr .tab.active:not(:last-child):after, .rtl .tab.active:not(:last-child):before {
    background: var(--color-step-1000);
}
.ltr .tab.active:not(:last-child):before, .rtl .tab.active:not(:last-child):after {
    background: var(--ion-color-primary);
}

.ltr .tab:not(:last-child):after, .rtl .tab:not(:last-child):before {
    width: 56px;
    height: 56px;
    background: var(--ion-color-primary);
    transform: scaleX(.5) rotate(45deg);
    border-radius: 2px;
    position: absolute;
    content: '';
    z-index: 3;
}
.ltr .tab.inactive:not(:last-child):after, .rtl .tab.inactive:not(:last-child):before {
    background: var(--color-step-800);
}
.ltr .tab:not(:last-child):after {
    right: -28px;
}
.rtl .tab:not(:last-child):before {
    left: -28px;
}

.ltr .tab:not(:last-child):before, .rtl .tab:not(:last-child):after {
    content: '';
    position: absolute;
    width: 56px;
    height: 56px;
    background: var(--color-step-1000);
    transform: scaleX(.5) rotate(45deg);
    border-radius: 2px;
    z-index: 2;
}
.ltr .tab:not(:last-child):before {
    right: -33px;
}
.rtl .tab:not(:last-child):after {
    left: -33px;
}

.tab.active .tab__text::before,
.tab.active .tab__text::after {
    content: "";
    height: 2px;
    width: calc(100% + 5px);
    position: absolute;
    background: var(--ion-color-primary);
    z-index: 4;
}
.tab.active:last-child .tab__text::before,
.tab.active:last-child .tab__text::after {
    width: calc(100%);
}
.ltr .tab.active .tab__text::before,
.ltr .tab.active .tab__text::after {
    left: 0;
}
.rtl .tab.active .tab__text::before,
.rtl .tab.active .tab__text::after {
    right: 0;
}

.rtl .tab.active .tab__text::after, .ltr .tab.active .tab__text::before {
    bottom: 0;
}
.rtl .tab.active .tab__text::before, .ltr .tab.active .tab__text::after {
    top: 0;
}
</style>
