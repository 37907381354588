

import {defineComponent} from "@vue/runtime-core";
import {IonIcon} from "@ionic/vue";
import {JourneyTab} from "@/models/JourneyTabModel";
import {useStore} from "vuex";
import {getImage} from "@/services/ImagesService";

export default defineComponent({
    name: 'VisualTabs',
    components: {
      IonIcon  
    },
    data() {
        return {
            store: useStore()
        }
    },
    props: {
        currentTab: { type: Number, default: -1 },
        tabs: { type: Array as () => JourneyTab[], default: () => [] as JourneyTab[]},
        activeTabIndexes: { type: Array, default: () => [] as number[]},
        tabType: { type: String, default: 'lesson' }
    },
    methods: {
        tabClass(index: number , tabNum: number) {
            
            /* if (this.$props.currentTab == -1 ) {
                return 'inactive';
            }*/
            if( this.$props.tabType === 'lesson'){
                if (!this.$props.activeTabIndexes.includes(tabNum - 1)) return 'inactive';
            }
            console.log(`index: ${index}`);
            console.log(`currentTab: ${this.$props.currentTab}`);
            console.log(`tabNum: ${this.$props.tabs[index].tabNum}`);
            if (this.$props.currentTab + 1 === this.$props.tabs[index].tabNum) {
                return 'active';
            }
            

        },
        async loadIcons () {

            if (this.$props.tabs[0]) {
                const {tabIcons} = await this.store.getters['app/language'];
                //const { tabIcons }: { tabIcons: string[] } = { tabIcons: [] };
                const tabIconsViewed: string[] = [];
                const tabs = this.$props.tabs as JourneyTab[];
                for (let i=0; i < tabs.length; i++) {
                    if (tabs[i].tabIconId === '' || tabs[i].tabIconId ==='00000000-0000-0000-0000-000000000000' || tabs[i].tabIconId === undefined) {

                        tabIconsViewed.push('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=');

                    }
                    else {
                        const iconImage = await getImage(tabs[i].tabIconId);
                        tabIconsViewed.push(iconImage.data);
                    }
                } 

                tabs.forEach((tab: JourneyTab, index: number) => tab.icon = tabIconsViewed[index]);
            }
        }
    },
    async ionViewWillEnter() {
        await this.loadIcons();
    },
    async beforeUpdate() {
        await this.loadIcons();
    },
    async mounted() {
        await this.loadIcons();
    }
});
