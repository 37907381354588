<template>
    <ion-page>
        <ion-content ref="introContent" :fullscreen="true" class="ion-no-padding" style="scroll-margin-block-start: 100px;">

            <!-- Custom Content Top -->
            <ion-item lines="full" mode="md" class="wysiwyg-container">
                <ion-label position="stacked" style="overflow: visible !important;">{{ language.customContent }}</ion-label>
                <MarkdownWYSIWYG v-model="journey.introTabTopText" v-if="contentLoaded" :placeholder="language.abideByCopyrightLawsPlaceholder"/>
            </ion-item>
            <AudioPicker v-if="contentLoaded" v-model:data="journey.introTabTopAudio" v-model:name="journey.introTabTopAudioFilename" :label="language.contentAudioFile" />

            
            <!-- Video -->
            <VideoPicker :label="language.journeyVideo1Url" v-model:videoUrl="journey.introTabVideoUrl" v-model:videoLabel="journey.introTabVideoLabel" />

            <!-- Template Text Top -->
            <TemplateItemPlaceholder :previewText="templateTab.text1 ?? ''"  id="template-text1"/>
            

            <!-- Scripture -->
            <ScripturePicker v-if="contentLoaded" @onRemove="handleRemoveScriptureRef" @onAdd="handleAddScriptureRef" :appInstanceId="journeySet.appInstanceId" :journeyId="journey.id" :tab=1 :scriptureReferences="tabSpecificScriptureReferences" />
            <AudioPicker v-if="contentLoaded" v-model:data="journey.introTabScriptureAudio" v-model:name="journey.introTabScriptureAudioFilename" :label="language.scriptureAudioFile" />

            <!-- Lesson Text -->
            <ion-item lines="full" mode="md" class="wysiwyg-container">
                <ion-label position="stacked" style="overflow: visible;">{{ language.customContent }}</ion-label>
                <MarkdownWYSIWYG v-model="journey.introTabBottomText" v-if="contentLoaded" :placeholder="language.abideByCopyrightLawsPlaceholder"/>
            </ion-item>
            <AudioPicker v-if="contentLoaded" v-model:data="journey.introTabBottomAudio" v-model:name="journey.introTabBottomAudioFilename" :label="language.contentAudioFile" />

            <!-- Template Text preview -->
            <TemplateItemPlaceholder  :previewText="templateTab.text2 ?? ''"  id="template-text2"/>

        </ion-content>
        <VisualTabs :currentTab="0" :tabs="template.tabs" :activeTabIndexes="activeTabs" v-if="template.tabs"/>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">
import {
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    //IonIcon,
    loadingController
} from '@ionic/vue';
import {computed, defineComponent, nextTick, ref} from 'vue';
import FixedFooter from "@/components/FixedFooter.vue";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {execute, executeWithoutSpinner} from "@/mixins/LoadingMixin";
import {getJourneySet} from "@/services/JourneySetService";
import {getTemplate} from "@/services/TemplateService";
import VisualTabs from "@/components/VisualTabs.vue";
import {JourneyTemplate} from "@/models/JourneyTemplateModel";
import {JourneySet} from '@/models/JourneySetModel';
import {Journey, validateJourney} from "@/models/JourneyModel";
import {getJourney, updateJourney} from "@/services/JourneyService";
import router from "@/router";
import { closeOutline, chevronDownOutline, chevronUpOutline, checkmarkOutline , documentText} from 'ionicons/icons';
import {ScriptureSelection} from "@/models/ScriptureSelectionModel";
import {useStore} from "vuex";
import {audioFileChanged} from "@/utils/AudioUtil";
import MarkdownWYSIWYG from "@/components/MarkdownWYSIWYG.vue";
import AudioPicker from '@/components/AudioPicker.vue';
import VideoPicker from '@/components/Video/VideoPicker.vue';
import ScripturePicker from '@/components/ScripturePicker.vue';
import TemplateItemPlaceholder from '@/components/TemplateItemPlaceholder.vue';
import { onBeforeRouteUpdate } from 'vue-router';
import { JourneyTab } from '@/models/JourneyTabModel';

export default defineComponent({
    name: 'IntroContent',
    components: {
        VisualTabs,
        IonPage,
        FixedFooter,
        IonContent,
        IonItem,
       // IonIcon,
        IonLabel,
        MarkdownWYSIWYG,
        AudioPicker,
        VideoPicker,
        ScripturePicker,
        TemplateItemPlaceholder
    },
    props: {
        journeySetId: {
            type: String,
            required: true
        },
        journeyId: {
            type: String,
            required: true
        }
    },
    async ionViewWillLeave() {
        await this.saveProgress();
    },
    async ionViewWillEnter() {
        this.contentLoaded = false;
        await this.store.dispatch('app/setPageTitle', this.language.journeyTab1);
        await execute(async () => {
            this.journeySet = await getJourneySet(this.journeySetId);
            this.journey = await getJourney(this.journeyId);
            this.journey = validateJourney(this.journey);
            this.selectedScriptureReferences = this.journey.scriptureSelections;
            this.template = await getTemplate(this.journeySet.templateId);
            if (this.template.enableCustomContent == undefined) this.template.enableCustomContent = false;
            this.contentLoaded = true;
            this.templateTab = this.template.tabs[0];
        }, this.language.errors.loadingJourney);
        this.introContent.$el.scrollToTop(300);
    },
    setup(props) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const contentLoaded = ref(false);
        const template = ref({} as JourneyTemplate);
        const journeySet = ref({} as JourneySet);
        const journey = ref({} as Journey);
        const templateTab = ref({} as JourneyTab);
        const introContent =ref();
        const hasFurtherEditing = computed(() => {
            return template.value.tabsCount === 4
        });
        
       
        const selectedScriptureReferences = ref([] as ScriptureSelection[]);
        const tabSpecificScriptureReferences = computed(() => selectedScriptureReferences.value.filter((reference) => reference.tabNum == 1))
        
        const activeTabs = computed(() => {
            const result: number[] = [];
            // if (hasFurtherEditing.value) result.push(2);
            // if (template.value.enableCustomContent) {
            //     result.push(0);
            //     result.push(hasFurtherEditing.value ? 3 : 2);
            // }
            switch (template.value.tabsCount) {
                case 1:
                    result.push(3);
                    break;
                case 2:
                    result.push(0);
                    result.push(3);
                    break;
                case 3:
                    if(template.value.enableCustomContent){
                        result.push(0);
                        result.push(1);
                        result.push(3);
                    }else{
                        result.push(1);
                    }
                    break;
                case 4:
                    if(template.value.enableCustomContent){
                        result.push(0);
                        result.push(1);
                        result.push(2);
                        result.push(3);
                    }else{
                        result.push(1);
                        result.push(2);
                    }
                    break;
                }
                return result
        })
        
        const hasVideo = computed(() => journey.value.introTabVideoLabel || journey.value.introTabVideoUrl);
        
        const save = async () => {
            await executeWithoutSpinner(async() => {
                journey.value.scriptureSelections = selectedScriptureReferences.value;
                await updateJourney(journey.value);
            }, language.value.errors.savingJourney);
        }

        const saveProgress = async () => {
            const loading = await loadingController.create({
                message: language.value.savingProgress,
            });
            await loading.present();
            await save();
            await loading.dismiss();
        }
        
        const saveAndNext = async () => {
            await saveProgress();
            switch (template.value.tabsCount) {
                case 2:
                    await router.push({ name: 'finalContent', params: props });
                    break;
                default:
                    await router.push({ name: 'scriptureContent', params: props });
                    break;  
                }
            //await router.push({ name: 'scriptureContent', params: props });
        }

      const audioChanged = async (event: any) => {
        const loading = await loadingController.create({
          message: language.value.savingProgress,
        });
        await loading.present();
        await nextTick();
        const { filename, data } = await audioFileChanged(event);
        journey.value.scriptureAudioFilename = filename;
        journey.value.scriptureAudio = data;
        await loading.dismiss();
      };

        const handleAddScriptureRef = (newRef: ScriptureSelection) => {
            selectedScriptureReferences.value.push(newRef);
        }

        const handleRemoveScriptureRef = (id: string) => {
            const index = selectedScriptureReferences.value.findIndex((reference) => reference.id == id);
            selectedScriptureReferences.value.splice(index, 1);
        }


        return {
            store,
            language,
            saveProgress,
            save,
            template,
            templateTab,
            journeySet,
            journey,
            introContent,
            audioChanged,
            closeOutline,
            chevronDownOutline,
            chevronUpOutline,
            checkmarkOutline,
            documentText,
            selectedScriptureReferences,
            tabSpecificScriptureReferences,
            contentLoaded,
            hasVideo,
            activeTabs,
            handleAddScriptureRef,
            handleRemoveScriptureRef,
            footerButtons: computed(() => new FooterButtonsBuilder()
                .addClearBackButton()
                .addPrimaryButton( hasFurtherEditing.value, async () => await saveAndNext(), language.value.next).create())
        }
    }
});

</script>

<style scoped>

.custom-button {
    margin-bottom: 20px;
}

.form-section-header {
    padding: 20px 40px;
    color: var(--color-step-600);
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

</style>