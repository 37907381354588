<template>
    <ion-page>
        <ion-content ref="tabContent" :fullscreen="true" class="ion-no-padding"  style="scroll-margin-block-start: 20px;">
            <div v-show="tab.id">

                <!-- Main Image -->
                <ion-item v-if="!mainImage.data" lines="full" mode="md">
                    <button v-if="!mainImage.data" class="custom-button button-outline" @click="openImageSelectModal">{{ language.contentMainImage }}</button>
                </ion-item>
                <ion-item v-else lines="full" mode="md">
                    <ion-icon slot="start" :src="mainImage.data" class="icon-preview"></ion-icon>
                    <ion-label>
                        <h2>{{ mainImage.filename }}</h2>
                    </ion-label>
                    <div class="icon-container" @click="removeImg"><ion-icon :icon="closeOutline" class="edit-forward"></ion-icon></div>
                </ion-item>

                <!-- Custom Content Placeholder -->
                <div class="place-holder-container" v-if="template.enableCustomContent || scriptureTab || extraTab">
                    <ion-icon class="icon " :icon="newspaper" ></ion-icon>
                    <span>{{ language.customTextToBeAdded }}</span>
                    <span>{{ language.whenCreatingAJourney }}</span>
                </div>

                <!-- Video Placeholder -->
                <div class="place-holder-container" v-if="template.enableCustomContent || scriptureTab || extraTab">
                    <ion-icon class="icon " :icon="videocam" ></ion-icon>
                    <span class="inline label">{{ language.journeyVideoHeader }}</span>
                    <span>{{ language.language.whenCreatingAJourney }}</span>
                </div>

                <!-- Top Template Content -->
                <ion-item lines="full" mode="md" class="wysiwyg-container">
                    <ion-label position="stacked" style="overflow: visible;">{{ language.contentText }}<span v-if="scriptureTab">{{ language.beforeScripture }}</span></ion-label>
                    <MarkdownWYSIWYG v-model="tab.text1" v-if="tabLoaded" :placeholder="language.textareaPlaceholder"/>
                </ion-item>
                <AudioPicker v-if="tabLoaded" v-model:data="tab.audio1" v-model:name="tab.audio1Filename" :label="language.audioFile" />
                

                <!-- Scripture Placeholder-->
                <div class="place-holder-container" v-if="template.enableCustomContent || scriptureTab || extraTab">
                    <ion-icon  class="icon" src="/assets/img/noun-book-6264518.svg"></ion-icon>
                    <span>{{ language.scriptureSection }}</span>
                    <span>{{ language.language.whenCreatingAJourney }}</span>
                </div>
                
                <!-- Custom Content Placeholder -->
                <div class="place-holder-container" v-if="template.enableCustomContent || scriptureTab || extraTab">
                    <ion-icon class="icon " :icon="newspaper" ></ion-icon>
                    <span class="">{{ language.customTextToBeAdded }}</span>
                    <span>{{ language.whenCreatingAJourney }}</span>
                </div>

                <!-- Bottom Template Content -->
                <ion-item lines="full" mode="md" class="wysiwyg-container" v-if="template.enableCustomContent || scriptureTab || extraTab">
                    <ion-label position="stacked" style="overflow: visible;">{{ language.contentText }}{{ language.afterScripture }}</ion-label>
                    <MarkdownWYSIWYG v-model="tab.text2" v-if="tabLoaded" :placeholder="language.textareaPlaceholder"/>
                </ion-item>
                <AudioPicker v-if="template.enableCustomContent || scriptureTab || extraTab" v-model:data="tab.audio2" v-model:name="tab.audio2Filename" :label="language.audioFile" />

                <!-- Copyright Audio -->
                <AudioPicker v-if="tabLoaded && conclusionTab" v-model:data="tab.copyrightAudio" v-model:name="tab.copyrightAudioFilename" :label="language.copyrightAudioFile"  
                :buttonLabel="language.copyrightAudioReminder"/>
            </div>
        </ion-content>
        <VisualTabs :currentTab="tabNum" :tabs="template.tabs" :activeTabIndexes="activeTabs" :tabType="template" v-if="template.tabs"/>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonIcon, IonItem, IonLabel, IonPage} from '@ionic/vue';
import FixedFooter from '@/components/FixedFooter.vue';
import {computed, ref, onBeforeUpdate, defineComponent} from 'vue';
import router from '@/router';
import {onBeforeRouteUpdate, useRoute} from "vue-router";
import VisualTabs from "@/components/VisualTabs.vue";
import { JourneyTemplate } from "@/models/JourneyTemplateModel";
import {execute, executeWithoutSpinner, saveProgress} from "@/mixins/LoadingMixin";
import {getTemplate, updateTemplate} from "@/services/TemplateService";
import {JourneyTab, validateTab} from "@/models/JourneyTabModel";
import {openSelectImageModal} from '@/services/SelectModalService';
import {IMAGE_GROUP_TYPES, ImageGroupId} from "@/models/ImageGroupTypes";
import {Image} from "@/models/ImageModel";
import { closeOutline,musicalNote , videocam , bookOutline , bookmarks , newspaper} from "ionicons/icons";
import {getTab, updateTab} from "@/services/TabService";
import {getImage} from "@/services/ImagesService";
import {useStore} from "vuex";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import {getTranslatedNumber} from "@/utils/NumberTranslate";
import MarkdownWYSIWYG from "@/components/MarkdownWYSIWYG.vue";
import AudioPicker from '@/components/AudioPicker.vue';


export default defineComponent({
    name: 'TabDetails',
    components: {
        IonItem,
        IonLabel,
        IonContent,
        IonPage,
        IonIcon,
        VisualTabs,
        FixedFooter,
        AudioPicker,
        MarkdownWYSIWYG
    },
    async ionViewWillLeave() {
        await saveProgress(this.save);
        clearInterval(this.saveInterval);
    },
    async ionViewWillEnter() {
        await this.pageSetup();
        this.saveInterval = setInterval(this.save, 30000);
        this.tabContent.$el.scrollToTop(300);
    },
    setup() {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const route = useRoute();
        const saveInterval = ref();
        const template = ref({} as JourneyTemplate);
        const tab = ref({} as JourneyTab);
        const templateId = ref(route.params.id);
        const tabId = ref(route.params.tabId);
        const isImageSelectionOpen = ref(false);
        const setOpen = (state: boolean) => isImageSelectionOpen.value = state;
        const mainImage = ref({} as Image);
        const tabIndex = computed(() => template.value.tabs?.map((tab: JourneyTab) => tab.tabNum).indexOf(tab.value.tabNum));
        const introTab = computed(() => tab.value.tabNum === 1);
        const scriptureTab = computed(() => tab.value.tabNum === 2);
        const extraTab = computed(() => tab.value.tabNum === 3);
        const conclusionTab = computed(() => tab.value.tabNum === 4);
        const tabNum = computed(() => tab.value.tabNum - 1);
        const nextText = computed(() => conclusionTab.value ? language.value.finishAndSave : language.value.next);
        const showNextIcon = computed(() => !conclusionTab.value);
        const tabLoaded = ref(false);
        const tabContent = ref();

        const activeTabs = computed(() => {
            const result = [];
            for(let i = 0 ; i < template.value.tabs.length ; i++) {
                result.push(i);
            }
            return result
        });
        
        const pageSetup = async () => {
            tabLoaded.value = false;
            tab.value = {} as JourneyTab;
            await execute(async () => {
                template.value = await getTemplate(templateId.value as string);
                tab.value = await getTab(tabId.value as string);
                tab.value = validateTab(tab.value);
                if (tab.value.imageId) {
                    mainImage.value = await getImage(tab.value.imageId);
                } else {
                    mainImage.value = {} as Image;
                }
                tab.value.text1 = tab.value.text1?.trim() ? tab.value.text1 : '';
                tab.value.text2 = tab.value.text2?.trim() ? tab.value.text2 : '';
                tabLoaded.value = true;
                await store.dispatch('app/setPageTitle', language.value.templateTab + getTranslatedNumber(tabIndex.value + 1));
            }, language.value.errors.gettingTemplateInfo);
            tabContent.value.$el.scrollToTop(300);
        }

        const save = async () => {
            await executeWithoutSpinner(async() => {
                await updateTab(tab.value);
            }, language.value.errors.savingProgress);
        }

        onBeforeUpdate(() => {
            templateId.value = route.params.id;
            tabId.value = route.params.tabId;
        });
        
        onBeforeRouteUpdate(async (to, from, next) => {
            templateId.value = to.params.id;
            tabId.value = to.params.tabId;
            next();
            await saveProgress(save);
            await pageSetup();
        });

        const openImageSelectModal = async () => {
            const imageSelect = await openSelectImageModal(IMAGE_GROUP_TYPES.get(ImageGroupId.JourneyImages) || '', ImageGroupId.JourneyImages);
            imageSelect.onWillDismiss().then(({ data }: any) => {
                tab.value.imageId = data.id;
                mainImage.value = data;
            });
        }

        const removeImg = () => {
            tab.value.imageId = '';
            mainImage.value = {} as Image;
        }

        const handleNext = async () => {
            await execute(async() => {
                await updateTab(tab.value);
                if (tab.value.tabNum === 4) {
                    template.value.complete = true;
                    await updateTemplate(template.value);
                    await router.push({ name: 'templates'});
                } else {
                    await router.push({name: 'tab',
                        params: {
                            id: templateId.value,
                            tabId: template.value.tabs[tabIndex.value+1].id
                        }
                    });
                }
            }, language.value.errors.savingProgress);
        }

        const handleBack = async () => {
            await execute(async() => {
                await updateTab(tab.value);
                if (tabIndex.value === 0) {
                    router.push({name: 'templateName', params: {id: templateId.value}});
                } else {
                    await router.push({name: 'tab',
                        params: {
                            id: templateId.value,
                            tabId: template.value.tabs[tabIndex.value-1].id
                        }
                    });
                }
            }, language.value.errors.savingProgress);
        }
    
        return {
            store,
            language,
            pageSetup,
            tab,
            template,
            templateId,
            nextText,
            showNextIcon,
            tabId,
            activeTabs,
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearBackButton(async() => await handleBack())
                .addPrimaryButton(showNextIcon.value, async () => await handleNext(), nextText.value)
                .create()
            ),
            setOpen,
            openImageSelectModal,
            mainImage,
            closeOutline,
            musicalNote,
            bookOutline,
            bookmarks,
            videocam,
            newspaper,
            removeImg,
            saveInterval,
            save,
            tabIndex,
            tabNum,
            introTab,
            scriptureTab,
            extraTab,
            conclusionTab,
            tabLoaded,
            tabContent
        }
    }
});
</script>

<style scoped>
.custom-button {
    margin-bottom: 20px;
}



.form-section-header {
    padding: 20px 40px;
    color: var(--color-step-600);
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
</style>